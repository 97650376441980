// src/components/EpicMenu.js

import React, { useState, useRef, useEffect } from "react";
import { menuItems } from "./data/menuItems.js";
import Banner from "../Section/Banner";

const MenuIcon = () => (
  <svg
    height="21"
    viewBox="0 0 21 21"
    width="21"
    xmlns="http://www.w3.org/2000/svg"
    className="h-10 w-10"
  >
    <g
      fill="none"
      fillRule="evenodd"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="m4.5 7.5h12" />
      <path d="m6.5 10.5h8" />
      <path d="m8.5 13.5h4" />
    </g>
  </svg>
);

const XIcon = () => (
  <svg
    height="21"
    viewBox="0 0 21 21"
    width="21"
    xmlns="http://www.w3.org/2000/svg"
    className="h-10 w-10"
  >
    <g
      fill="none"
      fillRule="evenodd"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <circle cx="10.5" cy="10.5" r="8" />
      <path d="m6.5 8.5h8" />
      <path d="m8.5 10.5h4" />
      <path d="m9.5 12.5h2" />
    </g>
  </svg>
);

const ArrowDownIcon = () => (
  <svg
    className="w-4 h-4 transform group-open:rotate-180 transition-transform duration-300"
    fill="none"
    stroke="currentColor"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M19 9l-7 7-7-7"
    />
  </svg>
);

const MenuEpicEvils = () => {
  const [activeMenu, setActiveMenu] = useState(null);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const timeoutRef = useRef(null);

  const handleMouseEnter = (index) => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
      timeoutRef.current = null;
    }
    setActiveMenu(index);
  };

  const handleMouseLeave = () => {
    timeoutRef.current = setTimeout(() => {
      setActiveMenu(null);
    }, 200);
  };

  useEffect(() => {
    if (isMobileMenuOpen) {
      document.body.classList.add("overflow-hidden");
    } else {
      document.body.classList.remove("overflow-hidden");
    }
    return () => {
      document.body.classList.remove("overflow-hidden");
    };
  }, [isMobileMenuOpen]);

  return (
    <div className="shadow-sm fixed w-full z-50">
      <Banner />
      <nav className="bg-footer-bg border-b border-footer-border px-4 py-3 shadow-sm fixed w-full z-50">
        <div className="max-w-7xl mx-auto flex items-center justify-between">
          <div className="flex-shrink-0">
            <a
              href="https://epicevils.com"
              className="font-ryomen text-footer-logo text-logo"
            >
              EpIC EVILs
            </a>
          </div>

          <div className="hidden md:flex items-center justify-center space-x-8">
            <ul className="flex space-x-8">
              {menuItems.map((item, index) => (
                <li
                  key={index}
                  className="relative"
                  onMouseEnter={() => handleMouseEnter(index)}
                  onMouseLeave={handleMouseLeave}
                  aria-haspopup="true"
                  aria-expanded={activeMenu === index ? "true" : "false"}
                >
                  <a
                    href={item.link}
                    className="text-footer-link hover:text-footer-link-hover transition-colors duration-300 font-medium py-2 text-menu"
                  >
                    {item.title}
                  </a>
                  {item.submenu && item.submenu.length > 0 && (
                    <ul
                      className={`absolute left-0 top-full mt-0 w-48 bg-white border border-footer-border rounded-md shadow-lg ${activeMenu === index ? "block animate-fadeIn" : "hidden"
                        }`}
                      onMouseEnter={() => handleMouseEnter(index)}
                      onMouseLeave={handleMouseLeave}
                      role="menu"
                    >
                      {item.submenu.map((subitem, subIndex) => (
                        <li key={subIndex}>
                          <a
                            href={subitem.link}
                            className="block px-4 py-2 text-gray-700 hover:bg-gray-100 hover:text-footer-link-hover transition-colors duration-200 text-menu"
                          >
                            {subitem.title}
                          </a>
                        </li>
                      ))}
                    </ul>
                  )}
                </li>
              ))}
            </ul>
          </div>
          <div className="hidden md:flex items-center space-x-4">
            <li className="flex items-center justify-center">
              <div className="flex items-center space-x-8">
                <div className="flex flex-col items-center hover:underline hover:text-white transition-colors duration-200">
                  <a
                    href="https://twitter.com/epicevils"
                    className="flex flex-col items-center space-y-1 bg-[#FF8FA3] p-2 rounded-xl hover:bg-footer-link-hover transition-colors duration-200"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <svg
                      width="31"
                      height="31"
                      viewBox="0 0 1200 1227"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-6 w-6"
                    >
                      <path
                        d="M714.163 519.284L1160.89 0H1055.03L667.137 450.887L357.328 0H0L468.492 681.821L0 1226.37H105.866L515.491 750.218L842.672 1226.37H1200L714.137 519.284H714.163ZM569.165 687.828L521.697 619.934L144.011 79.6944H306.615L611.412 515.685L658.88 583.579L1055.08 1150.3H892.476L569.165 687.854V687.828Z"
                        fill="#590D22"
                      />
                    </svg>
                  </a>
                </div>
                <div className="flex flex-col items-center hover:underline hover:text-white transition-colors duration-200">
                  <a
                    href="https://chat.whatsapp.com/DWPZWe4mJhv6d4ZNQCCrlO"
                    className="flex flex-col items-center space-y-1 bg-[#FF8FA3] p-2 rounded-xl hover:bg-footer-link-hover transition-colors duration-200"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <svg
                      width="28"
                      height="31"
                      viewBox="0 0 31 31"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-6 w-6"
                    >
                      <path
                        d="M30.3139 14.8011C30.1739 10.9697 28.5594 7.34056 25.8073 4.67136C23.0552 2.00216 19.3784 0.499287 15.5446 0.476567H15.4722C12.8904 0.474649 10.3527 1.14494 8.10857 2.42148C5.86449 3.69802 3.99142 5.53682 2.67367 7.75695C1.35592 9.97709 0.6389 12.5021 0.593155 15.0834C0.547411 17.6648 1.17452 20.2135 2.41278 22.479L1.09794 30.3469C1.0958 30.363 1.09712 30.3794 1.10182 30.395C1.10651 30.4106 1.11448 30.425 1.12518 30.4373C1.13588 30.4496 1.14907 30.4594 1.16387 30.4662C1.17867 30.473 1.19475 30.4765 1.21103 30.4766H1.23365L9.01561 28.7456C11.0263 29.7109 13.2282 30.2118 15.4586 30.2112C15.6004 30.2112 15.7421 30.2112 15.8838 30.2112C17.8458 30.1552 19.7773 29.7112 21.5667 28.9048C23.3562 28.0984 24.9682 26.9456 26.3098 25.5129C27.6514 24.0802 28.696 22.396 29.3832 20.5574C30.0704 18.7189 30.3867 16.7625 30.3139 14.8011V14.8011ZM15.8099 27.6252C15.6923 27.6252 15.5747 27.6252 15.4586 27.6252C13.4874 27.6277 11.5444 27.156 9.79366 26.2501L9.39559 26.042L4.11815 27.289L5.09221 21.9497L4.86604 21.5667C3.78579 19.725 3.20393 17.6336 3.17778 15.4985C3.15163 13.3635 3.68208 11.2584 4.71689 9.39075C5.75171 7.52306 7.25518 5.95715 9.07924 4.84724C10.9033 3.73732 12.985 3.1217 15.1194 3.06101C15.238 3.06101 15.3571 3.06101 15.4767 3.06101C18.6992 3.07056 21.7889 4.34564 24.0802 6.61154C26.3715 8.87743 27.681 11.9528 27.7265 15.1749C27.7719 18.3971 26.5498 21.5081 24.3234 23.8378C22.0969 26.1675 19.0444 27.5293 15.8235 27.6298L15.8099 27.6252Z"
                        fill="#590D22"
                      />
                      <path
                        d="M10.2894 8.16663C10.1057 8.17022 9.92456 8.21063 9.75673 8.28548C9.5889 8.36033 9.43779 8.4681 9.31236 8.6024C8.95801 8.96579 7.96736 9.84034 7.91006 11.6769C7.85277 13.5134 9.13594 15.3304 9.31537 15.5852C9.49481 15.84 11.7686 19.8072 15.5141 21.416C17.7156 22.3645 18.6806 22.5273 19.3063 22.5273C19.5642 22.5273 19.7587 22.5002 19.9622 22.4881C20.6483 22.4459 22.1969 21.6528 22.5346 20.7903C22.8724 19.9278 22.895 19.1739 22.806 19.0231C22.7171 18.8723 22.4728 18.7637 22.1049 18.5707C21.737 18.3777 19.9321 17.4127 19.5928 17.277C19.467 17.2184 19.3316 17.1832 19.1932 17.173C19.1031 17.1777 19.0155 17.2043 18.938 17.2506C18.8605 17.2968 18.7954 17.3613 18.7484 17.4383C18.4469 17.8138 17.7548 18.6295 17.5225 18.8648C17.4718 18.9232 17.4093 18.9703 17.3392 19.0031C17.2691 19.0358 17.1928 19.0534 17.1154 19.0548C16.9728 19.0485 16.8333 19.0109 16.7068 18.9447C15.6135 18.4804 14.6167 17.8155 13.768 16.9845C12.975 16.2029 12.3022 15.3081 11.7716 14.3292C11.5666 13.9492 11.7716 13.7532 11.9586 13.5753C12.1456 13.3973 12.3461 13.1516 12.5391 12.9389C12.6975 12.7573 12.8295 12.5543 12.9312 12.3358C12.9838 12.2344 13.0104 12.1215 13.0085 12.0073C13.0067 11.893 12.9765 11.7811 12.9206 11.6814C12.8317 11.4914 12.1667 9.62321 11.8546 8.87381C11.6013 8.23298 11.2997 8.21187 11.0358 8.19227C10.8187 8.17719 10.5699 8.16965 10.3211 8.16211H10.2894"
                        fill="#590D22"
                      />
                    </svg>
                  </a>
                </div>
              </div>
            </li>
          </div>

          <div className="md:hidden">
            <button
              onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
              className="text-footer-link hover:text-footer-link-hover focus:outline-none focus:text-footer-link-hover"
              aria-label="Toggle menu"
            >
              {isMobileMenuOpen ? <XIcon /> : <MenuIcon />}
            </button>
          </div>
        </div>

        {isMobileMenuOpen && (
          <div className="md:hidden mt-4">
            <ul className="flex flex-col space-y-4">
              {menuItems.map((item, index) => (
                <li key={index} className="relative">
                  {item.submenu && item.submenu.length > 0 ? (
                    <details className="group">
                      <summary className="flex justify-between items-center cursor-pointer text-footer-link hover:text-footer-link-hover transition-colors duration-300 font-medium px-2 py-1 text-menumobile">
                        {item.title}
                        <ArrowDownIcon />
                      </summary>
                      <ul className="mt-2 ml-4 flex flex-col space-y-2 max-h-60 overflow-y-auto">
                        {item.submenu.map((subitem, subIndex) => (
                          <li key={subIndex}>
                            <a
                              href={subitem.link}
                              className="block px-2 py-1 text-footer-link hover:bg-gray-100 hover:text-footer-link-hover transition-colors duration-200 rounded-md text-menumobile"
                            >
                              {subitem.title}
                            </a>
                          </li>
                        ))}
                      </ul>
                    </details>
                  ) : (
                    <a
                      href={item.link}
                      className="text-footer-link hover:text-footer-link-hover transition-colors duration-300 font-medium px-2 py-1 text-menumobile"
                    >
                      {item.title}
                    </a>
                  )}
                </li>
              ))}

              <li className="border-t border-footer-border"></li>
              <li className="flex items-center justify-center">
                <div className="flex items-center space-x-8">
                  <div className="flex flex-col items-center hover:underline hover:text-white transition-colors duration-200">
                    <a
                      href="https://twitter.com/epicevils"
                      className="flex flex-col items-center space-y-1 bg-[#FF8FA3] p-2 rounded-xl"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 1200 1227"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-12 w-12 p-2 rounded-xl"
                      >
                        <path
                          d="M714.163 519.284L1160.89 0H1055.03L667.137 450.887L357.328 0H0L468.492 681.821L0 1226.37H105.866L515.491 750.218L842.672 1226.37H1200L714.137 519.284H714.163ZM569.165 687.828L521.697 619.934L144.011 79.6944H306.615L611.412 515.685L658.88 583.579L1055.08 1150.3H892.476L569.165 687.854V687.828Z"
                          fill="#590D22"
                        />
                      </svg>
                    </a>
                    <span className="text-footer-link font-bold text-2xl">
                      Twitter
                    </span>
                  </div>
                  <div className="flex flex-col items-center hover:underline hover:text-white transition-colors duration-200">
                    <a
                      href="https://chat.whatsapp.com/DWPZWe4mJhv6d4ZNQCCrlO"
                      className="flex flex-col items-center space-y-1 bg-[#FF8FA3] p-2 rounded-xl"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <svg
                        width="28"
                        height="31"
                        viewBox="0 0 31 31"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-12 w-12 p-2 rounded-xl"
                      >
                        <path
                          d="M30.3139 14.8011C30.1739 10.9697 28.5594 7.34056 25.8073 4.67136C23.0552 2.00216 19.3784 0.499287 15.5446 0.476567H15.4722C12.8904 0.474649 10.3527 1.14494 8.10857 2.42148C5.86449 3.69802 3.99142 5.53682 2.67367 7.75695C1.35592 9.97709 0.6389 12.5021 0.593155 15.0834C0.547411 17.6648 1.17452 20.2135 2.41278 22.479L1.09794 30.3469C1.0958 30.363 1.09712 30.3794 1.10182 30.395C1.10651 30.4106 1.11448 30.425 1.12518 30.4373C1.13588 30.4496 1.14907 30.4594 1.16387 30.4662C1.17867 30.473 1.19475 30.4765 1.21103 30.4766H1.23365L9.01561 28.7456C11.0263 29.7109 13.2282 30.2118 15.4586 30.2112C15.6004 30.2112 15.7421 30.2112 15.8838 30.2112C17.8458 30.1552 19.7773 29.7112 21.5667 28.9048C23.3562 28.0984 24.9682 26.9456 26.3098 25.5129C27.6514 24.0802 28.696 22.396 29.3832 20.5574C30.0704 18.7189 30.3867 16.7625 30.3139 14.8011V14.8011ZM15.8099 27.6252C15.6923 27.6252 15.5747 27.6252 15.4586 27.6252C13.4874 27.6277 11.5444 27.156 9.79366 26.2501L9.39559 26.042L4.11815 27.289L5.09221 21.9497L4.86604 21.5667C3.78579 19.725 3.20393 17.6336 3.17778 15.4985C3.15163 13.3635 3.68208 11.2584 4.71689 9.39075C5.75171 7.52306 7.25518 5.95715 9.07924 4.84724C10.9033 3.73732 12.985 3.1217 15.1194 3.06101C15.238 3.06101 15.3571 3.06101 15.4767 3.06101C18.6992 3.07056 21.7889 4.34564 24.0802 6.61154C26.3715 8.87743 27.681 11.9528 27.7265 15.1749C27.7719 18.3971 26.5498 21.5081 24.3234 23.8378C22.0969 26.1675 19.0444 27.5293 15.8235 27.6298L15.8099 27.6252Z"
                          fill="#590D22"
                        />
                        <path
                          d="M10.2894 8.16663C10.1057 8.17022 9.92456 8.21063 9.75673 8.28548C9.5889 8.36033 9.43779 8.4681 9.31236 8.6024C8.95801 8.96579 7.96736 9.84034 7.91006 11.6769C7.85277 13.5134 9.13594 15.3304 9.31537 15.5852C9.49481 15.84 11.7686 19.8072 15.5141 21.416C17.7156 22.3645 18.6806 22.5273 19.3063 22.5273C19.5642 22.5273 19.7587 22.5002 19.9622 22.4881C20.6483 22.4459 22.1969 21.6528 22.5346 20.7903C22.8724 19.9278 22.895 19.1739 22.806 19.0231C22.7171 18.8723 22.4728 18.7637 22.1049 18.5707C21.737 18.3777 19.9321 17.4127 19.5928 17.277C19.467 17.2184 19.3316 17.1832 19.1932 17.173C19.1031 17.1777 19.0155 17.2043 18.938 17.2506C18.8605 17.2968 18.7954 17.3613 18.7484 17.4383C18.4469 17.8138 17.7548 18.6295 17.5225 18.8648C17.4718 18.9232 17.4093 18.9703 17.3392 19.0031C17.2691 19.0358 17.1928 19.0534 17.1154 19.0548C16.9728 19.0485 16.8333 19.0109 16.7068 18.9447C15.6135 18.4804 14.6167 17.8155 13.768 16.9845C12.975 16.2029 12.3022 15.3081 11.7716 14.3292C11.5666 13.9492 11.7716 13.7532 11.9586 13.5753C12.1456 13.3973 12.3461 13.1516 12.5391 12.9389C12.6975 12.7573 12.8295 12.5543 12.9312 12.3358C12.9838 12.2344 13.0104 12.1215 13.0085 12.0073C13.0067 11.893 12.9765 11.7811 12.9206 11.6814C12.8317 11.4914 12.1667 9.62321 11.8546 8.87381C11.6013 8.23298 11.2997 8.21187 11.0358 8.19227C10.8187 8.17719 10.5699 8.16965 10.3211 8.16211H10.2894"
                          fill="#590D22"
                        />
                      </svg>
                    </a>
                    <span className="text-footer-link font-bold text-2xl">
                      WhatsApp
                    </span>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        )}
      </nav>
    </div>
  );
};

export default MenuEpicEvils;
